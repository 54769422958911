var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mortgage-block" },
    [
      _c(
        "content-wrapper-block",
        [
          _c("page-title", [_vm._v("Варианты покупки")]),
          _c(
            "div",
            { staticClass: "mortgage-block__content mortgage-content" },
            [
              _c("div", { staticClass: "mortgage-content__cards" }, [
                _c(
                  "div",
                  { staticClass: "mortgage-content__first" },
                  _vm._l(_vm.purchaseOptions, function (option, index) {
                    return _c(
                      "shadow-card",
                      {
                        key: index,
                        staticClass: "mortgage-card mortgage-card_big",
                      },
                      [
                        _c("div", { staticClass: "mortgage-card__title" }, [
                          _vm._v(" " + _vm._s(option.title) + " "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mortgage-card__description" },
                          [_vm._v(" " + _vm._s(option.description) + " ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "mortgage-card__button" },
                          [
                            _c(
                              "ui-button",
                              _vm._b(
                                {
                                  staticClass: "mortgage-table__footer-button",
                                  attrs: { outline: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickButton(option.url)
                                    },
                                  },
                                },
                                "ui-button",
                                {
                                  [!option.url
                                    ? "data-popup-with-chats-title"
                                    : undefined]: option.form_name,
                                  [!option.url
                                    ? "data-popup-with-chats"
                                    : undefined]: true,
                                },
                                false
                              ),
                              [_vm._v(" " + _vm._s(option.text_button) + " ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mortgage-content__middle" },
                  [
                    _c(
                      "shadow-card",
                      { staticClass: "mortgage-card mortgage-card--table" },
                      [
                        _c("div", { staticClass: "mortgage-card__title" }, [
                          _vm._v("Ипотека от " + _vm._s(_vm.minFrom) + "%"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mortgage-card__description" },
                          [_vm._v("у банков-партнеров")]
                        ),
                        _c("div", { staticClass: "mortgage-content__divider" }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "mortgage-content__table mortgage-table",
                          },
                          [
                            _c(
                              "ui-table",
                              {
                                staticClass: "mortgage-table__table",
                                attrs: { data: _vm.banksArray },
                              },
                              [
                                _c("ui-table-column", {
                                  attrs: { label: "Банк", prop: "bank" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mortgage-table__bank",
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "mortgage-table__bank-logo",
                                                attrs: {
                                                  src: scope.row.bank_image_url,
                                                  alt: scope.row.bank_name,
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "mortgage-table__bank-name",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(scope.row.bank_name)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _c("ui-table-column", {
                                  attrs: { label: "Ставка", prop: "bank_bet" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                "mortgage-content__link":
                                                  scope.row.bank_legal_text,
                                              },
                                              attrs: {
                                                role: scope.row.bank_legal_text
                                                  ? "button"
                                                  : "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clickRow(scope.row)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(scope.row.bank_bet) +
                                                  "% "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _c("ui-table-column", {
                                  attrs: {
                                    label: "Мин.взнос",
                                    prop: "bank_min_pay",
                                    subscript: _vm.percent,
                                  },
                                }),
                                _c("ui-table-column", {
                                  attrs: {
                                    label: "Срок до",
                                    prop: "bank_max_time",
                                    subscript: _vm.year,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mortgage-table__footer" },
                          [
                            _c(
                              "ui-button",
                              {
                                staticClass: "mortgage-table__footer-button",
                                attrs: {
                                  outline: "",
                                  "data-popup-with-chats": "",
                                  "data-popup-with-chats-title":
                                    "Рассчитать ипотеку",
                                },
                              },
                              [_vm._v(" Рассчитать ипотеку ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "mortgage-content__right mortgage-promo mortgage-content__right--no-padding",
                },
                [
                  _c("MortgagePromo", {
                    attrs: {
                      title: "Повышение цены <br>с 01.06.2023",
                      date: new Date(2023, 5, 1),
                    },
                  }),
                ],
                1
              ),
              0 && _vm.bannerPromo
                ? _c(
                    "div",
                    {
                      staticClass:
                        "mortgage-content__right mortgage-promo mortgage-content__right--no-padding",
                    },
                    [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            srcset: _vm.bannerPromo.image.source,
                            media: "(min-width: 1200px)",
                          },
                        }),
                        _c("source", {
                          attrs: {
                            srcset: _vm.bannerPromo.image_tablet.source,
                            media: "(min-width: 768px)",
                          },
                        }),
                        _c("img", {
                          staticClass: "mortgage-promo__image",
                          attrs: {
                            src: _vm.bannerPromo.image_mobile.source,
                            alt: "promo",
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "mortgage-promo__background" }),
                      _c(
                        "div",
                        { staticClass: "mortgage-promo__content" },
                        [
                          _c("div", { staticClass: "mortgage-promo__title" }, [
                            _vm._v(" " + _vm._s(_vm.bannerPromo.title) + " "),
                          ]),
                          _c(
                            "div",
                            { staticClass: "mortgage-promo__subtitle" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.bannerPromo.subtitle) + " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "mortgage-promo__description" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.bannerPromo.description) + " "
                              ),
                            ]
                          ),
                          _c(
                            "ui-button",
                            {
                              staticClass: "mortgage-promo__action",
                              attrs: {
                                "data-popup-with-chats": "",
                                "data-popup-with-chats-title":
                                  "Узнать подробнее",
                                "data-popup-with-chats-source-id":
                                  "mortgagePromo",
                              },
                            },
                            [_vm._v(" Узнать подробнее ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              0 && !_vm.bannerPromo
                ? _c(
                    "div",
                    {
                      staticClass: "mortgage-content__right mortgage-form",
                      attrs: { id: _vm.idPayment },
                    },
                    [
                      _c("div", { staticClass: "mortgage-form__header" }, [
                        _c("div", { staticClass: "mortgage-form__title" }, [
                          _vm._v(" " + _vm._s(_vm.title) + " "),
                        ]),
                        _c("div", { staticClass: "mortgage-form__cost" }, [
                          _vm._v(" от " + _vm._s(_vm.minPrice) + " "),
                          _c("span", [_vm._v("₽")]),
                        ]),
                      ]),
                      _c("div", { staticClass: "mortgage-form__divider" }),
                      _c("div", {
                        staticClass: "mortgage-form__description",
                        domProps: {
                          innerHTML: _vm._s(
                            "Платеж указан из минимальной стоимости  апартамента&nbsp;, при минимальной процентной ставке&nbsp;, сроке кредитования 30&nbsp;лет и первоначальным взносом 50%."
                          ),
                        },
                      }),
                      _c(
                        "form",
                        {
                          ref: "callback-form",
                          staticClass: "callback-block__form callback-form",
                          attrs: { id: _vm.id, method: "POST" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "callback-form__button" },
                            [
                              _c(
                                "ui-button",
                                {
                                  attrs: {
                                    "data-popup-with-chats": "",
                                    "data-popup-with-chats-title":
                                      "Узнать подробнее",
                                  },
                                },
                                [_vm._v(" Узнать подробнее ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c("mortgage-modal-dialog", { ref: "modal-mortgage" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }