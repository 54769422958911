var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mortgage-promo-block" }, [
    _vm._m(0),
    _c("div", { staticClass: "mortgage-promo-block__content" }, [
      _c("div", { staticClass: "mortgage-promo-block__logo" }, [_c("Logo")], 1),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "mortgage-promo-block__buttons" },
        [
          _c(
            "ui-button",
            {
              staticClass: "mortgage-promo-block__button",
              attrs: {
                outline: "",
                light: "",
                "data-popup-with-chats-title": "Ипотека от 0,11%",
                "data-popup-with-chats": "",
                "data-popup-with-chats-source-id": "mortgageMarch",
              },
            },
            [_vm._v(" Узнать подробнее ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", { staticClass: "mortgage-promo-block__back" }, [
      _c("source", {
        attrs: {
          srcset: require("@/assets/images/promo/mortgagePromo.jpg"),
          media: "(min-width: 1200px)",
        },
      }),
      _c("source", {
        attrs: {
          srcset: require("@/assets/images/promo/mortgagePromo-tablet.jpg"),
          media: "(min-width: 768px)",
        },
      }),
      _c("source", {
        attrs: {
          srcset: require("@/assets/images/promo/mortgagePromo-mob.jpg"),
          media: "(min-width: 0)",
        },
      }),
      _c("img", {
        staticClass: "mortgage-promo-block__image",
        attrs: {
          src: require("@/assets/images/promo/mortgagePromo-mob.jpg"),
          alt: "promo",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mortgage-promo-block__title" }, [
      _vm._v(" Ипотека"),
      _c("br"),
      _vm._v("от 0,11% "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }