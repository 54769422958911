<template>
  <div class="shadow-card">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ShadowCard",
};
</script>

<style scoped lang="scss">
.shadow-card {
  background-color: #ffffff;
  box-shadow: 6px 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px;

  @media screen and (min-width: $screen-sm) {
    padding: 30px;
  }
}
</style>
