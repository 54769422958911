var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      staticClass: "mortgage-modal-dialog",
      attrs: { visible: _vm.visible },
      on: { close: _vm.close },
    },
    [
      _c("template", { slot: "title" }, [
        _c("div", { staticClass: "mortgage-modal-dialog__title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
      ]),
      _c("template", { slot: "content" }, [
        _c("div", { staticClass: "mortgage-modal-dialog__content" }, [
          _vm._v(" " + _vm._s(_vm.text) + " "),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }