<template>
	<modal-dialog class="mortgage-modal-dialog" :visible="visible" @close="close">
		<template slot="title">
			<div class="mortgage-modal-dialog__title">
				{{title}}
			</div>
		</template>
		<template slot="content">
			<div class="mortgage-modal-dialog__content">
				{{text}}
			</div>
		</template>
	</modal-dialog>
</template>
  
<script>
  import ModalDialog from "@/components/dialogs/ModalDialog";
  
  export default {
    name: "MortgageModalDialog",
    components: { ModalDialog },
    data: () => ({
      visible: false,
			text: '',
			title: '',
    }),
    methods: {
      show(title, text) {
        this.visible = true;
				this.text = text
				this.title = title
      },
      close() {
        this.visible = false;
      },
    },
  };
</script>
  
<style lang="scss">
	.mortgage-modal-dialog {
		&.modal-dialog {
			.modal-body {
				&.modal-dialog__body {
					background: #FFFFFF;
					padding: 24px 16px;
					width: 280px;
					@media screen and (min-width: 768px) {
						padding: 36px 48px;
						width: 681px;
					}
				}
				&__title {
					margin-bottom: 8px;
					@media screen and (min-width: 768px) {
						margin-bottom: 12px;
					}
				}
			}
			.modal-body__close {
				top: 12px;
				right: 12px;
				@media screen and (min-width: 768px) {
					top: 24px;
					right: 24px;
				}
				&:focus {
					background: transparent;
				}
				svg {
					path {
						fill: #8c8c8d;
					}
				}
			}
		}
		&__content {
			font-style: normal;
			font-weight: 300;
			line-height: 135%;
			letter-spacing: 0.03em;
			color: #18181B;
			font-family: $font-family-rubik;
			font-size: 12px;
			@media screen and (min-width: 768px) {
				font-size: 14px;
			}
		}
		&__title {
			font-style: normal;
			font-weight: 400;
			line-height: 130%;
			letter-spacing: 0.05em;
			color: #18181B;
			font-size: 30px;
			@media screen and (min-width: 768px) {
				font-size: 48px;
			}
		}
	}
</style>
  