<template>
  <div class="mortgage-promo-block">
    <picture class="mortgage-promo-block__back">
      <source srcset="@/assets/images/promo/mortgagePromo.jpg" media="(min-width: 1200px)" />
      <source srcset="@/assets/images/promo/mortgagePromo-tablet.jpg" media="(min-width: 768px)" />
      <source srcset="@/assets/images/promo/mortgagePromo-mob.jpg" media="(min-width: 0)" />
      <img class="mortgage-promo-block__image" src="@/assets/images/promo/mortgagePromo-mob.jpg" alt="promo" />
    </picture>
    <div class="mortgage-promo-block__content">
      <div class="mortgage-promo-block__logo">
        <Logo />
      </div>
      <div class="mortgage-promo-block__title">
        Ипотека<br>от 0,11%
      </div>
      <div class="mortgage-promo-block__buttons">
        <ui-button
          class="mortgage-promo-block__button"
          outline
          light
          data-popup-with-chats-title="Ипотека от 0,11%"
          data-popup-with-chats
          data-popup-with-chats-source-id="mortgageMarch"
        >
          Узнать подробнее 
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/icons/Logo";
import UiButton from "@/components/ui/UIButton";
export default {
  name: "MortgagePromo",
  components: {
    Logo,
    UiButton
  }
}
</script>

<style lang="scss">
.mortgage-promo-block {
  height: 100%;
  &__back {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.05em;
    color: #E14313;
    white-space: nowrap;
    margin: 20px 0;
  }
  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  &__image {
    width: 254px;
    margin-bottom: 34px;
    img {
      width: 100%;
    }
    @media screen and (min-width: 768px) {
      width: 284px;
      margin-bottom: 40px;
    }
    @media screen and (min-width: 1200px) {
      width: 375px;
    }
  }
  &__logo {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 8px;
    @media screen and (min-width: 768px) {
      margin-top: 12px;
      margin-bottom: 16px;
    }
    @media screen and (min-width: 1200px) {
      margin-top: 64px;
      margin-bottom: 70px;
    }
  }
  &__buttons {
    margin-top: auto;
    margin-bottom: 24px;
    @media screen and (min-width: 768px) {
      margin-bottom: 24px;
    }
    @media screen and (min-width: 1200px) {
      margin-bottom: 64px;
    }
    .ui-button {
      padding: 12px 11px;
      margin-right: 10px;
      @media screen and (min-width: 768px) {
        padding: 12px 25px;
        margin-right: 16px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
