<template>
  <div class="mortgage-block">
    <content-wrapper-block>
      <page-title>Варианты покупки</page-title>
      <div class="mortgage-block__content mortgage-content">
        <div class="mortgage-content__cards">
          <div class="mortgage-content__first">
            <!-- <shadow-card class="mortgage-card mortgage-card_big">
              <div class="mortgage-card__title">СПЕЦИАЛЬНЫЕ УСЛОВИЯ:<br/> ИПОТЕКА ОТ <span style="color: #E14313">3500 р/мес</span></div>
              <div class="mortgage-card__description">Каникулы на твоей улице</div>
              <div class="mortgage-card__button">
                <router-link :to="{name: 'HolidaysPage'}">
                  <ui-button outline>Узнать подробнее</ui-button>
                </router-link>
              </div>
            </shadow-card> -->
            <!-- <shadow-card class="mortgage-card mortgage-card__plug">
              <div class=""><icon-additional /></div>
              <div class="mortgage-card__description">Скоро для вас будет  доступен дополнительный вариант покупки </div>
            </shadow-card> -->
            <shadow-card
              v-for="(option, index) in purchaseOptions"
              :key="index"
              class="mortgage-card mortgage-card_big"
            >
              <div class="mortgage-card__title">
                {{ option.title }}
              </div>
              <div class="mortgage-card__description">
                {{ option.description }}
              </div>
              <div class="mortgage-card__button">
                <ui-button
                  class="mortgage-table__footer-button"
                  outline
                  v-bind="{
                    [!option.url ? 'data-popup-with-chats-title' : undefined]: option.form_name,
                    [!option.url ? 'data-popup-with-chats' : undefined]: true
                  }"
                  @click="clickButton(option.url)"
                >
                  {{ option.text_button }}
                </ui-button>
              </div>
            </shadow-card>
          </div>
          <div class="mortgage-content__middle">
            <shadow-card class="mortgage-card mortgage-card--table">
              <div class="mortgage-card__title">Ипотека от {{ minFrom }}%</div>
              <div class="mortgage-card__description">у банков-партнеров</div>
              <div class="mortgage-content__divider" />
              <div class="mortgage-content__table mortgage-table">
                <ui-table class="mortgage-table__table" :data="banksArray">
                  <ui-table-column label="Банк" prop="bank">
                    <template slot-scope="scope">
                      <div class="mortgage-table__bank">
                        <img
                          class="mortgage-table__bank-logo"
                          :src="scope.row.bank_image_url"
                          :alt="scope.row.bank_name"
                        />
                        <span class="mortgage-table__bank-name">{{
                          scope.row.bank_name
                        }}</span>
                      </div>
                    </template>
                  </ui-table-column>
                  <ui-table-column
                    label="Ставка"
                    prop="bank_bet"
                  >
                    <template slot-scope="scope">
                      <div
                        :role="scope.row.bank_legal_text ? 'button' : ''"
                        :class="{'mortgage-content__link': scope.row.bank_legal_text}"
                        @click="clickRow(scope.row)"
                      >
                        {{scope.row.bank_bet}}%
                      </div>
                    </template>
                  </ui-table-column>
                  <ui-table-column
                    label="Мин.взнос"
                    prop="bank_min_pay"
                    :subscript="percent"
                  />
                  <ui-table-column
                    label="Срок до"
                    prop="bank_max_time"
                    :subscript="year"
                  />
                </ui-table>
              </div>
              <div class="mortgage-table__footer">
                <ui-button
                  class="mortgage-table__footer-button"
                  outline
                  data-popup-with-chats
                  data-popup-with-chats-title="Рассчитать ипотеку"
                >
                  Рассчитать ипотеку
                </ui-button>
              </div>
            </shadow-card>
          </div>
        </div>
        <div class="mortgage-content__right mortgage-promo mortgage-content__right--no-padding">
          <MortgagePromo
            title="Повышение цены <br>с 01.06.2023"
            :date="new Date(2023, 5, 1)"
          />
        </div>
        <div v-if="0 && bannerPromo" class="mortgage-content__right mortgage-promo mortgage-content__right--no-padding">
          <picture>
            <source :srcset="bannerPromo.image.source" media="(min-width: 1200px)" />
            <source :srcset="bannerPromo.image_tablet.source" media="(min-width: 768px)" />
            <img class="mortgage-promo__image" :src="bannerPromo.image_mobile.source" alt="promo" />
          </picture>
          <div class="mortgage-promo__background"></div>
          <div class="mortgage-promo__content">
            <div class="mortgage-promo__title"> {{ bannerPromo.title }} </div>
            <div class="mortgage-promo__subtitle"> {{ bannerPromo.subtitle }} </div>
            <div class="mortgage-promo__description"> {{ bannerPromo.description }} </div>
            <ui-button
              data-popup-with-chats
              data-popup-with-chats-title="Узнать подробнее"
              data-popup-with-chats-source-id="mortgagePromo"
              class="mortgage-promo__action"
            >
              Узнать подробнее
            </ui-button>
          </div>
        </div>
        <div v-if="0 && !bannerPromo" :id="idPayment" class="mortgage-content__right mortgage-form">
          <div class="mortgage-form__header">
            <div class="mortgage-form__title">
              {{ title }}
            </div>
            <div class="mortgage-form__cost">
              от {{ minPrice }} <span>₽</span>
            </div>
          </div>
          <div class="mortgage-form__divider" />
          <div
            class="mortgage-form__description"
            v-html="
              'Платеж указан из минимальной стоимости  апартамента&nbsp;, при минимальной процентной ставке&nbsp;, сроке кредитования 30&nbsp;лет и первоначальным взносом 50%.'
            "
          >
            <!-- При покупке студии площадью {{minArea}}м² с оформлением ипотеки в Сбербанке и при первоначальном взносе {{bank_min_pay}}%. -->
            <!-- Платёж указан для апартаментов стоимостью 9&nbsp;244&nbsp;900&nbsp;руб., первоначальном взносе 20%, сроке кредитования 15 лет и процентной ставке 8%. -->
          </div>
          <form
            :id="id"
            ref="callback-form"
            method="POST"
            class="callback-block__form callback-form"
            @submit.prevent="submit"
          >
            <!-- <div class="callback-form__row">
              <div class="callback-form__col">
                <ui-input ref="form-name" :id="id+'-name'"
                  placeholderValue="Имя" hint="Введите корректные данные" v-model="name" placeholder-white required />
              </div>
              <div class="callback-form__col">
                <ui-input ref="form-phone" :id="id+'-phone'" type="tel"
                  hint="Введите корректный номер телефона" placeholderValue="телефон" v-model="phone"
                  v-mask="'+7 (###) ###-##-##'" placeholder-white required />
                <div class="form-phone_valid" v-if="!isPhoneValid">Введите корректный номер телефона</div>
              </div>
            </div> -->
            <div class="callback-form__button">
              <ui-button
                data-popup-with-chats
                data-popup-with-chats-title="Узнать подробнее"
              >
                Узнать подробнее
              </ui-button>
              <!-- <ui-button type="submit" @click="submitForm">получить предложение</ui-button> -->
            </div>
          </form>
        </div>
      </div>
    </content-wrapper-block>
    <mortgage-modal-dialog ref="modal-mortgage" />
  </div>
</template>

<script>
import PageTitle from "@/components/elements/PageTitle";
import MortgagePromo from "@/components/elements/MortgagePromo";
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import ShadowCard from "@/components/elements/ShadowCard";
import UiButton from "@/components/ui/UIButton";
import UiInput from "@/components/ui/UIInput";
import UiTable from "@/components/ui/UITable";
import UiTableColumn from "@/components/ui/UITableColumn";
import MortgageModalDialog from "@/components/dialogs/MortgageModalDialog";
// import IconAdditional from '@/components/icons/IconAdditionalPurchase'
import { mapState } from "vuex";

export default {
  name: "MortgageBlock",
  components: {
    UiTableColumn,
    UiTable,
    UiButton,
    UiInput,
    ShadowCard,
    ContentWrapperBlock,
    PageTitle,
    MortgageModalDialog,
    MortgagePromo,
    // IconAdditional
  },
  props: {
    banks: {
      type: Array,
      default: function () {
        return [];
      },
    },
    title: {
      type: String,
      default: "ЕЖЕМЕСЯЧНЫЙ ПЛАТЕЖ",
    },
  },
  data: () => ({
    id: "mortgage",
    idPayment: "payment-block__callback",
    name: "",
    phone: "",
    checkbox: true,
    validPhone: false,
    percent: "%",
    year: "лет",
  }),
  computed: {
    ...mapState({
      ipoteka: (state) => state.ipoteka,
      purchaseOptions: (state) => state.purchaseOptions,
      payPrograms: (state) => state.payPrograms,
      apartments: (state) => state.apartments,
      minPercent: (state) => state.minPercent,
      storage: (state) => state.storage,
    }),
    bannerPromo () {
      return this.storage.mortgage.promo.find(i => i.slug === 'promo' && i.activity)
    },
    isPhoneValid() {
      return this.phone.length >= 18 || this.phone.length < 1;
    },
    banksArray() {
      return this.banks.length > 0 ? this.banks : this.ipoteka;
    },
    minPrice() {
      let minAmount = 0;
      if (this.apartments.length > 0) {
        minAmount = this.apartments[0].discount_cost;
        this.apartments.forEach((item) => {
          if (minAmount > item.discount_cost) {
            minAmount = item.discount_cost;
          }
        });
      }
      return this.$ipoteka(minAmount, minAmount * 0.5, this.bank_min_bet, 30, this.storage.mortgage.apply_coefficient ? this.storage.mortgage.coefficient : 1);
    },
    minFrom() {
      return this.minPercent;
    },
    bank_min_bet() {
      let min = 999;

      this.banksArray.forEach((bank) => {
        if (min > parseFloat(bank.bank_bet)) min = parseFloat(bank.bank_bet);
      });

      return min;
    },
    maxTime() {
      let max = 0;

      this.banksArray.forEach((bank) => {
        if (max < parseFloat(bank.bank_max_time))
          max = parseFloat(bank.bank_max_time);
      });

      return max;
    },
    minArea() {
      let min =
        this.apartments.length > 0 ? parseFloat(this.apartments[0].area) : 999;

      this.apartments.forEach((apartment) => {
        if (min > parseFloat(apartment.area)) min = parseFloat(apartment.area);
      });

      return min;
    },
  },
  methods: {
    sendMessage() {
      window.Comagic.addOfflineRequest(
        {
          name: "Имя: " + this.name + " | " + "Форма: " + this.title,
          phone: this.phone,
          message:
            "Описание: " +
            this.title +
            " | " +
            "URL: " +
            document.URL +
            " | " +
            "ID: " +
            this.idPayment,
        },
        () => {}
      );
      this.name = "";
      this.phone = "";
    },
    submit(e) {
      if (this.phone.length === 18 && this.name.trim()) {
        this.success = true;
        this.sendMessage();
        e.preventDefault();
      }
      // if (this.phone.length === 18 && this.name.trim()) {
      //   this.validPhone = false
      //   this.showSuccessDialog()
      //   this.sendMessage()
      // } else {
      //   this.validPhone = true
      // }
    },
    submitForm() {
      this.$refs["form-name"].$refs.VueInputUi.classList.add("attempt");
      this.$refs["form-phone"].$refs.VueInputUi.classList.add("attempt");
      this.$refs["form-agreement"].$refs.VueInputUi.classList.add("attempt");
    },
    clickButton (url) {
      if (!url) return

      let href = url

      if (!url.includes('http')) {
        href = this.$router.resolve(url).href
      }

      window.open(href, '_blank');
    },
    clickRow (item) {
      if (item.bank_legal_text) {
        this.$refs["modal-mortgage"].show('Ипотека от ' + item.bank_bet + '%', item.bank_legal_text);
      }
    }
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=PT+Sans+Caption&display=swap");
.mortgage-table {
  &__table {
    .ui-table__body-cell {
      font-size: 10px;
      margin: 8px 5px 7px 10px;
      width: 25%;

      @media screen and (min-width: $screen-lg) {
        margin-left: 11px;
      }
    }

    .ui-table__header-cell {
      width: 25%;
      margin-left: 10px;

      @media screen and (min-width: $screen-lg) {
        margin-left: 10px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.mortgage-block {
  padding-top: 25px;
  background-color: #f4f4f4;

  &__content,
  .mortgage-content {
    display: flex;
    flex-direction: column;

    &__link {
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }

    @media screen and (min-width: $screen-lg) {
      flex-direction: row;
    }

    &__cards {
      display: flex;
      flex-direction: column;
      flex: 1;

      @media screen and (min-width: $screen-sm) {
        flex-direction: row;
      }

      @media screen and (min-width: $screen-lg) {
        flex: 0 0 700px;
        flex-direction: row;
      }
    }

    &__first {
      width: 100%;
      padding-right: 0;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $screen-sm) {
        flex: 0 0 270px;
        padding-right: 30px;
      }
    }

    &__middle {
      width: 100%;
      padding-right: 0;
      display: flex;

      @media screen and (min-width: $screen-lg) {
        flex: 0 0 370px;
        padding-right: 30px;
      }
    }
    &__right,
    .mortgage-form {
      flex: 1;
      background-color: #18181b;
      box-shadow: 0 2px 21px rgba(0, 0, 0, 0.11);
      color: #ffffff;
      padding: 30px;
      margin-bottom: -15px;
      margin-left: -15px;
      margin-right: -15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &--no-padding {
        padding: 0 !important;
      }

      @media screen and (min-width: $screen-lg) {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 30px;
      }

      &__header {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        @media screen and (min-width: $screen-sm) {
          flex-direction: row;
          align-items: center;
        }
        @media screen and (min-width: $screen-lg) {
          flex-direction: column;
          align-items: baseline;
        }
      }

      &__title {
        font-family: $font-family-bebas;
        font-size: 20px;
        line-height: 130%;
        letter-spacing: 0.05em;
        color: #ffffff;

        @media screen and (min-width: $screen-sm) {
          font-size: 26px;
          margin-right: 40px;
        }
      }

      &__cost {
        font-family: $font-family-bebas;
        color: #ffffff;
        font-weight: 700;
        font-size: 48px;
        line-height: 130%;
        letter-spacing: 0.05em;

        span {
          font-family: "PT Sans Caption", sans-serif;
          font-weight: 400;
        }

        @media screen and (min-width: $screen-mini) {
          font-size: 64px;
        }
      }

      &__divider {
        opacity: 0.5;
        width: 66px;
        border-top: 1px solid #ffffff;
        margin-top: 20px;
        margin-bottom: 28px;
      }

      &__description {
        font-family: $font-family-rubik;
        font-weight: 300;
        font-size: 14px;
        line-height: 170%;
        letter-spacing: 0.03em;
        color: #ffffff;
        opacity: 0.7;
      }
    }

    .mortgage-promo {
      position: relative;

      &__image {
        width: 100%;

        @media screen and (min-width: $screen-lg) {
          position: absolute;
          height: 100%;
          object-fit: cover;
        }
      }

      &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: red;
        background: linear-gradient(0deg, rgba(24, 24, 27, 0.3), rgba(24, 24, 27, 0.3));
        box-shadow: 0px 2px 21px rgba(0, 0, 0, 0.11);
      }

      &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &__title {
        font-family: $font-family-bebas;
        font-size: 30px;
        line-height: 130%;
        letter-spacing: 0.05em;
        margin-bottom: 12px;


        @media screen and (min-width: $screen-sm) {
          margin-top: auto;
          font-size: 42px;
          margin-bottom: 24px;
        }
      }

      &__subtitle {
        font-family: $font-family-bebas;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.05em;

        @media screen and (min-width: $screen-sm) {
          font-size: 26px;
          line-height: 31px;
          margin-bottom: 8px;
        }
      }

      &__description {
        font-family: $font-family-rubik;;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 170%;
      }

      &__action {
        margin-top: 30px;
        margin-bottom: 30px;

        @media screen and (min-width: $screen-sm) {
          margin-top: auto;
        }
      }
    }

    &__divider {
      padding-top: 25px;
      margin-bottom: 25px;
      border-bottom: 1px solid #18181b;
      opacity: 0.1;
    }
    input {
      display: none;
    }
    .callback-form {
      margin-top: 15px;

      &__row {
        display: flex;
        justify-content: space-between;
      }

      &__col {
        position: relative;
        width: 50%;
        box-sizing: border-box;
        padding-right: 25px;

        &:last-child {
          padding-right: 0;
        }

        .vue-input-ui {
          width: 100%;
          min-width: 100%;
        }
        .form-phone_valid {
          position: absolute;
          bottom: -9px;
          font-family: Rubik;
          font-size: 11px;
          text-transform: none;
          color: #ed0000;
          line-height: 132.5%;
        }
      }

      &__button {
        margin: 25px 0 25px 0;
      }
    }

    &__table,
    .mortgage-table {
      &__bank {
        display: flex;
        flex-direction: column;

        &-logo {
          width: 100%;
          height: 100%;
          max-width: 50px;
          max-height: 17px;
        }

        &-name {
          font-weight: 300;
          font-size: 4px;
          line-height: 132.5%;
          display: block;
          margin-top: 2px;
          letter-spacing: 0;

          @media screen and (min-width: $screen-xs) {
            font-size: 5px;
          }
        }
      }

      &__footer {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        &-button {
          font-size: 12px;
        }
      }
    }

    .mortgage-card_big {
      padding: 50px 30px;
    }
    .mortgage-card {
      margin-bottom: 30px;

      &__title {
        font-family: $font-family-bebas;
        font-size: 20px;
        line-height: 130%;
        letter-spacing: 0.05em;
        color: #18181b;
        @media screen and (min-width: $screen-sm) {
          font-size: 24px;
        }
      }

      &__description {
        font-family: $font-family-rubik;
        font-weight: 300;
        font-size: 14px;
        line-height: 132.5%;
        letter-spacing: 0.03em;
        color: #18181b;
        opacity: 0.5;
      }
      &__button {
        margin-top: 44px;
        font-size: 12px;
      }

      &--table {
        flex: 1;
      }
      &__plug {
        flex: 1;
        display: flex;
        align-items: center;

        .mortgage-card__description {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
